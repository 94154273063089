/* App.css */
body {
  height: 100vh;
  background-image: url("https://i.imgur.com/LrIWEK9.png");
  background-size: cover; /* Makes sure the image covers the whole page */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents tiling */
  margin: 0;
  font-family: Arial, sans-serif;
}
.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height to center vertically */
}
h1 {
  color: #fff;
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 20px;
}
.social-icons {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 40px;
}

.social-icons a img {
  width: 90px; /* Set width for logos */
  height: 90px; /* Set height for logos */
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 60px;
}

.social-icons a img:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.random-button {
  padding: 20px 40px;
  background-color: #dc1fff;
  font-size: 50px;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 800;
  cursor: pointer;
}

.random-button:hover {
  background-color: #0056b3;
}

/* Toast Notification */
.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #2ecc71;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.toast.show {
  opacity: 1;
}

/* Modal Styling */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-height: 80vh; /* Set a maximum height for mobile responsiveness */
  text-align: center;
  max-width: 500px;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  width: 100%;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.copy-button {
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #45a049;
}

.modal-close {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-close:hover {
  background-color: #ff3333;
}
@media (max-width: 768px) {
  .modal-content {
    max-width: 95%; /* Take up more width on smaller screens */
    padding: 15px; /* Reduce padding on smaller screens */
  }
}
